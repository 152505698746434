import { ritmDate } from '@/utils'

export const dateChecker = (value) => {
  if (!isNaN(value)) return value
  const date = this.$ritmDate.date(value)

  if (value && !isNaN(ritmDate.date(date))) return ritmDate.toFormat(value, 'DD.MM.YYYY HH:mm')

  return value
}

export const falseChecker = (value) => {
  if (value === undefined || value === null || value === false || value === 0) return true
  if (!value && value !== 0) return true
  if (Array.isArray(value) && !value?.length) return true
  if (value instanceof Date) return false
  if (typeof value === 'object' && Object.keys(value).length === 0) return true

  return false
}

export const parseValue = (value, type) => {
  switch (type) {
    case 'date':
      return value ? ritmDate.toFormat(value, 'YYYY-MM-DD') : null
    default:
      return value
  }
}

export const getObjectConfig = (ids, fields) => {
  const config = {
    where: { field: 'id', op: Array.isArray(ids) ? 'in' : '=', value: ids }
  }

  config.only = fields?.filter(f => !f.reflection)?.map(f => f.origin_title || f.title)

  const reflections = fields?.filter(f => f.reflection)

  reflections.forEach(r => {
    if (!config.include) config.include = {}

    const through = r.reflection.through

    config.include[r.title] = { only: ['id', r.reflection?.default_show_attribute].filter(e => e) }

    if (through) config.include[through] = {}
  })

  if (!config.only.find(e => e === 'id')) {
    config.only.push('id')
  }

  return config
}
